import { LoadingButton } from "@mui/lab";
import { ReactNode } from "react";

interface PrimaryButtonProps {
  label: string;
  onClickAction?: any;
  isLoading?: boolean;
  icon?: ReactNode;
  isDisabled?: boolean;
}

export const PrimaryButton = (props: PrimaryButtonProps) => {
  return (
    <LoadingButton
      variant="contained"
      disableElevation
      sx={{ textTransform: "none" }}
      startIcon={props.icon}
      loading={props.isLoading}
      loadingPosition="start"
      type="submit"
      fullWidth
      onClick={props.onClickAction}
      disabled={props.isDisabled}
    >
      {props.label}
    </LoadingButton>
  );
};
