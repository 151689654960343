import { Box, Divider, Typography } from "@mui/material";
import CEODrawer from "../../../../../components/comercial/CEODrawer";
import { type IStore } from "../../../../../services/api/Store/types";
import { ImageCarousel } from "../../../../../components/general/ImageCarousel";
import { generalDarkGrey, dmPrimaryOrange } from "../../../../../styles/colors";
import TimeRangeInterpreter from "../../../../../components/comercial/TimeRangeInterpreter";
import printResponseError from "../../../../../utils/printResponseError";
import { useEffect, useState } from "react";
import IExcursion from "../../../../../services/api/Excursion/IExcursion";
import excursionApi from "../../../../../services/api/Excursion";
import CustomCard from "../../../../../components/general/CustomCard";
import horizontalScrollbarStyles from "../../../../../styles/horizontalScrollbarStyles";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import defaultExcursionUrl from "../../../../../assets/svg/defaultExcursion.svg";
import NearMeIcon from "@mui/icons-material/NearMe";
import ArticleIcon from "@mui/icons-material/Article";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

const AgencyView = () => {
  const selectedStore: IStore = JSON.parse(
    localStorage.getItem("selectedStore")
  );

  const [storeExcursions, setStoreExcursions] = useState<IExcursion[]>([]);

  const fetchStoreExcursions = async () => {
    try {
      const storeExcursionResponse = await excursionApi.getExcursionsFromAStore(
        selectedStore.id
      );
      setStoreExcursions(storeExcursionResponse);
    } catch (error) {
      printResponseError("Error fetching store excursions: ", error);
    }
  };

  useEffect(() => {
    fetchStoreExcursions();
  }, []);

  const sectionDivider = (
    <Divider
      sx={{
        width: "100%",
        color: generalDarkGrey,
      }}
    />
  );
  const renderStoreData = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      marginBottom="30px"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginBottom="10px"
      >
        <Typography variant="h3" fontWeight="bold" textAlign="center">
          {selectedStore.name}
        </Typography>
      </Box>
      <Box width="100%">
        <ImageCarousel imageUrls={selectedStore.imageOriginal} />
      </Box>

      <Box></Box>
      <Box>
        <Typography color={generalDarkGrey} textAlign="justify">
          {selectedStore.description}
        </Typography>
      </Box>
    </Box>
  );

  const renderStoreLocation = (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      margin="50px 0px"
      gap={3}
    >
      <MapContainer
        zoom={17}
        center={[selectedStore.lat, selectedStore.lng]}
        style={{ height: "400px", borderRadius: "20px" }}
        dragging={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          key={selectedStore.id}
          position={[selectedStore.lat, selectedStore.lng]}
        />
      </MapContainer>
      <Box display="flex" alignItems="center" gap={1}>
        <NearMeIcon />
        {/* remember to create a function to deal with blank attributes */}
        <Typography textAlign="justify">{`${selectedStore.logradouro}, ${
          selectedStore.number || "s/n"
        } - ${selectedStore.district}, ${selectedStore.city} - ${
          selectedStore.state
        }, ${selectedStore.CEP}`}</Typography>
      </Box>
    </Box>
  );

  const renderStoreWorkscale = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      margin="40px 0px 60px 0px"
      width="100%"
      gap={3}
    >
      <Typography variant="h5" fontWeight="bold">
        Horários de funcionamento
      </Typography>
      <TimeRangeInterpreter
        timeMonday={selectedStore.timeMonday}
        timeTuesday={selectedStore.timeTuesday}
        timeWednesday={selectedStore.timeWednesday}
        timeThursday={selectedStore.timeThursday}
        timeFriday={selectedStore.timeFriday}
        timeSaturday={selectedStore.timeSaturday}
        timeSunday={selectedStore.timeSunday}
      />
    </Box>
  );

  const renderStoreExcursions = (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      alignSelf="flex-start"
      width="100%"
      margin="50px 0px"
    >
      <Typography variant="h5" fontWeight="bold">
        Excursões desta agência
      </Typography>

      <Box
        display="flex"
        gap={2}
        width="100%"
        padding="10px 0px"
        sx={{
          overflowX: "auto",
          "@media(max-width:600px)": {
            width: "82vw",
          },
        }}
      >
        {storeExcursions.map((excursion, index) => (
          <Box key={index}>
            <CustomCard
              photoURL={excursion.imagePreview[0]}
              header={excursion.title}
              primaryText={`R$ ${excursion.price}`}
              secondaryText=""
              defaultPhoto={defaultExcursionUrl}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );

  const renderStoreContacts = (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="flex-start"
      margin="20px 0px 50px 0px"
    >
      <Typography fontWeight="bold" sx={{ marginBottom: "15px" }}>
        Informações de contato:
      </Typography>
      <Box display="flex" gap={1} alignItems="center" margin="5px 0px">
        <ArticleIcon sx={{ color: "primary.main" }} />
        <Typography>{selectedStore.CNPJ} (CNPJ)</Typography>
      </Box>
      <Box display="flex" gap={1} alignItems="center" margin="5px 0px">
        <LocalPhoneIcon sx={{ color: "primary.main" }} />
        <Typography>{selectedStore.phone}</Typography>
      </Box>
      <Box display="flex" gap={1} alignItems="center" margin="5px 0px">
        <EmailIcon sx={{ color: "primary.main" }} />
        <Typography>{selectedStore.email}</Typography>
      </Box>
    </Box>
  );

  const renderAddStorePageContent = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      margin="0px 10px"
    >
      {renderStoreData}
      {sectionDivider}
      {renderStoreLocation}
      {sectionDivider}
      {renderStoreWorkscale}
      {sectionDivider}
      {renderStoreExcursions}
      {sectionDivider}
      {renderStoreContacts}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderAddStorePageContent} />
    </Box>
  );
};

export default AgencyView;
