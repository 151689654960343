import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import InputMask from "react-input-mask";
import PixIcon from "@mui/icons-material/Pix";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PaidIcon from "@mui/icons-material/Paid";
import { useRef, useState } from "react";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { generalDarkGrey, dmYellow } from "../../../../styles/colors";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { PaymentMethod } from "../../../../types/types";

const CartPayment = () => {
  const auth = useAuth();

  const fullNameRef = useRef<HTMLInputElement>(null);
  const docNumberRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);

  const [cep, setCep] = useState<string>(auth.user.CEP);

  const streetRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const districtRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);

  const paymentMethodRef = useRef<HTMLSelectElement>(null);

  const CpfField = (
    <InputMask
      mask="999.999.999-99"
      disabled={false}
      maskChar="_"
      defaultValue={auth.user.docNumber}
      inputRef={docNumberRef}
    >
      {<TextField label="CPF*" sx={{ width: "200px" }} />}
    </InputMask>
  );

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar hideMidButtons />

      <form>
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          margin="30px 400px"
          sx={{
            "@media(max-width: 1160px)": {
              margin: "30px 100px",
            },
            "@media(max-width: 600px)": {
              margin: "30px",
            },
          }}
        >
          <Box display="flex" flexDirection="column" gap={1} margin="20px 0px">
            <Typography variant="h6">
              Confirme os dados para o pagamento
            </Typography>
            <Divider />
          </Box>
          <Box display="flex" gap={2}>
            <TextField
              label="Nome completo *"
              fullWidth
              defaultValue={auth.user.name}
              inputRef={fullNameRef}
            />
            {CpfField}
          </Box>
          <Box display="flex" gap={2}>
            <TextField
              label="Email *"
              type="email"
              placeholder="exemplo@email.com"
              fullWidth
              defaultValue={auth.user.email}
              inputRef={emailRef}
            />
            <InputMask
              mask="(99) 9.9999-9999"
              disabled={false}
              maskchar="_"
              inputRef={phoneNumberRef}
            >
              {
                <TextField
                  label="Telefone *"
                  type="phone"
                  fullWidth
                  defaultValue={auth.user.phone}
                />
              }
            </InputMask>
          </Box>

          <Box display="flex" flexDirection="column" gap={3}>
            <InputMask
              mask="99999-999"
              disabled={false}
              maskChar="_"
              value={cep}
              onChange={(e) => {
                setCep(e.target.value);
              }}
            >
              {<TextField label="CEP" fullWidth />}
            </InputMask>
            <Box display="flex" width="100%" gap={2}>
              <TextField
                label="Logradouro"
                sx={{ width: "75%" }}
                InputLabelProps={{ shrink: true }}
                defaultValue={auth.user.logradouro}
                inputRef={streetRef}
              />
              <TextField
                label="Número"
                sx={{ width: "25%" }}
                InputLabelProps={{ shrink: true }}
                defaultValue={auth.user.number}
                inputRef={numberRef}
              />
            </Box>
            <Box display="flex" width="100%" gap={2}>
              <TextField
                label="Bairro"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={auth.user.district}
                inputRef={districtRef}
              />
              <TextField
                label="Cidade"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={auth.user.city}
                inputRef={cityRef}
              />
            </Box>
            <Box display="flex" width="100%" gap={2}>
              <TextField
                label="Estado"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={auth.user.state}
                inputRef={stateRef}
              />
            </Box>
          </Box>
          <FormControl fullWidth>
            <InputLabel>Forma de pagamento *</InputLabel>
            <Select
              label="Forma de pagamento"
              fullWidth
              defaultValue={PaymentMethod.PIX}
              inputRef={paymentMethodRef}
            >
              <MenuItem value={PaymentMethod.PIX}>
                <Box display="flex" alignItems="center" gap={1}>
                  <PixIcon sx={{ color: "#00BDAE" }} />
                  Pix
                </Box>
              </MenuItem>
              <MenuItem value={PaymentMethod.CREDIT_CARD}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CreditCardIcon sx={{ color: generalDarkGrey }} />
                  Cartão de crédito
                </Box>
              </MenuItem>
              <MenuItem value={PaymentMethod.DEBIT_CARD}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CreditCardIcon sx={{ color: generalDarkGrey }} /> Cartão de
                  débito
                </Box>
              </MenuItem>
              <MenuItem value={PaymentMethod.BANK_SLIP}>
                <Box display="flex" alignItems="center" gap={1}>
                  <ReceiptOutlinedIcon />
                  Boleto bancário
                </Box>
              </MenuItem>
              <MenuItem value={PaymentMethod.MONEY}>
                <Box display="flex" alignItems="center" gap={1}>
                  <PaidIcon sx={{ color: dmYellow }} /> Dinheiro
                </Box>
              </MenuItem>

              <MenuItem value={PaymentMethod.CRIPTO}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CurrencyBitcoinIcon sx={{ color: dmYellow }} /> Criptomoeda
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit"
            disableElevation
            variant="contained"
            sx={{ textTransform: "none", margin: "30px 0px" }}
            size="large"
            fullWidth
          >
            Finalizar pagamento
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default CartPayment;
