import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Rating,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useEffect, useState } from "react";
import { ImageCarousel } from "../../../../components/general/ImageCarousel";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { userApi } from "../../../../services";
import employeeApi from "../../../../services/api/Employee";
import { IEmployee } from "../../../../services/api/Employee/types";
import serviceEmployeeApi from "../../../../services/api/ServiceEmployee";
import { IServiceEmployee } from "../../../../services/api/ServiceEmployee/types";
import { IServiceStore } from "../../../../services/api/ServiceStore/types";
import { IUser } from "../../../../services/api/User/types";
import { generalDarkGrey } from "../../../../styles/colors";
import { priceFormatter } from "../../../../utils/priceFormatter";
import DescriptionWrapper from "../../../../components/general/DescriptionWrapper";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import ratingApi from "../../../../services/api/Rating";
import { IRating } from "../../../../services/api/Rating/types";

export const ServicePage = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const defaultEmployeeIdParam = searchParams.get("employeeId");

  const selectedDateParam = searchParams.get("selectedDate");

  const CURRENT_DAY_INDEX = new Date().getDay();

  const [serviceEmployeeUsers, setServiceEmployeeUsers] = useState<IUser[]>([]);
  const [serviceEmployees, setServiceEmployees] = useState<IEmployee[]>([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(
    defaultEmployeeIdParam || ""
  );
  const [ratings, setRatings] = useState<IRating[]>([]);

  const [areEmployeesLoading, setAreEmployeesLoading] = useState(true);

  const [selectedEmployeeError, setSelectedEmployeeError] = useState(false);

  const selectedServiceStore: IServiceStore = JSON.parse(
    localStorage.getItem("selectedServiceStore")
  );

  const handleStoreClick = (): void => {
    localStorage.setItem(
      "selectedStore",
      JSON.stringify(selectedServiceStore.Store)
    );
    navigate("/store");
  };

  const handleFetchServiceEmployees = async () => {
    try {
      const serviceEmployeeRes: IServiceEmployee[] =
        await serviceEmployeeApi.getServiceEmployeeByServiceStoreId(
          selectedServiceStore.id
        );

      let iterableUser = null;
      const updatedServiceEmployeeUsers = [];
      const updatedServiceEmployees = [];

      for (let i = 0; i < serviceEmployeeRes.length; i++) {
        updatedServiceEmployees.push(serviceEmployeeRes[i].Employee);

        iterableUser = await userApi.getUser(
          serviceEmployeeRes[i].Employee.userId
        );

        updatedServiceEmployeeUsers.push(iterableUser);
      }

      setServiceEmployees(updatedServiceEmployees);
      setServiceEmployeeUsers(updatedServiceEmployeeUsers);

      if (!defaultEmployeeIdParam && updatedServiceEmployees.length > 0)
        setSelectedEmployeeId(updatedServiceEmployees[0].id);
    } catch (error) {
      console.warn("Error fetching service-employee: ", error);
    }

    setAreEmployeesLoading(false);
  };

  const handleScheduleServiceClick = async () => {
    if (selectedEmployeeId.length > 0) {
      setSelectedEmployeeError(false);
      try {
        const employeeRes = await employeeApi.getEmployee(selectedEmployeeId);

        if (employeeRes.userId !== auth.user.id) {
          const employeeUserRes = await userApi.getUser(employeeRes.userId);

          localStorage.setItem("selectedEmployee", JSON.stringify(employeeRes));

          localStorage.setItem(
            "selectedEmployeeName",
            JSON.stringify(employeeUserRes.name)
          );
          selectedDateParam
            ? navigate(`/scheduiling?selectedDate=${selectedDateParam}`)
            : navigate("/scheduiling");
        } else {
          alert("Não é possível agendar um serviço com você mesmo.");
        }
      } catch (error) {
        console.warn(error);
      }
    } else setSelectedEmployeeError(true);
  };

  const fetchServiceStoreRating = async () => {
    try {
      const ratingsResponse = await ratingApi.getRatingFromServiceStore(
        selectedServiceStore.id
      );
      setRatings(ratingsResponse);
      console.log(ratingsResponse);
    } catch (error) {
      console.error("Error fetching service-store ratings: ", error);
    }
  };

  useEffect(() => {
    handleFetchServiceEmployees();
    fetchServiceStoreRating();
  }, []);
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar hideMidButtons />
      <BackPageButton
        containerStyles={styles.backButton}
        iconStyles={styles.backIcon}
      />
      <Box
        width="100vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={8}
        margin="30px 0px"
        sx={{
          "@media(max-width: 930px)": {
            flexDirection: "column",
            gap: 4,
          },
        }}
      >
        {selectedServiceStore.imageOriginal?.length > 0 ? (
          <Box width="650px" height="450px">
            <ImageCarousel imageUrls={selectedServiceStore.imageOriginal} />
          </Box>
        ) : (
          <></>
        )}

        <Box width={400}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h4" fontWeight="bold">
              {selectedServiceStore.Service.name}
            </Typography>
            <Typography color="text.secondary" textAlign="justify">
              <DescriptionWrapper
                text={selectedServiceStore.description}
                max={200}
              />
            </Typography>
            <Typography variant="h5" fontWeight="bold" color="primary">
              {`R$ ${priceFormatter(
                selectedServiceStore.priceDay[CURRENT_DAY_INDEX]
              )}`}
            </Typography>
            {!areEmployeesLoading ? (
              <FormControl
                sx={{ margin: "15px 0px", minHeight: "65px" }}
                size="small"
              >
                <InputLabel>Selecione o profissional</InputLabel>
                <Select
                  label="Selecione o profissional"
                  value={selectedEmployeeId}
                  onChange={(e) => {
                    setSelectedEmployeeId(e.target.value);
                  }}
                  defaultValue={defaultEmployeeIdParam! ?? selectedEmployeeId}
                  error={!!selectedEmployeeError}
                >
                  {serviceEmployeeUsers.map((employeeUser: IUser, index) => (
                    <MenuItem
                      key={employeeUser.id}
                      value={serviceEmployees[index].id}
                    >
                      <ListItem disablePadding>
                        <ListItemAvatar>
                          <Avatar src={employeeUser.imagePreview} />
                        </ListItemAvatar>
                        <ListItemText primary={employeeUser.name} />
                      </ListItem>
                    </MenuItem>
                  ))}
                </Select>
                {selectedEmployeeError ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    É necessário selecionar um profissional para o serviço.
                  </FormHelperText>
                ) : null}
              </FormControl>
            ) : (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                margin="10px 0px 20px 0px"
              >
                <CircularProgress />
              </Box>
            )}

            <Button
              variant="contained"
              disableElevation
              startIcon={<EventNoteIcon />}
              onClick={handleScheduleServiceClick}
              sx={{ textTransform: "none" }}
            >
              Agendar serviço
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        width="100vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={2}
        marginBottom="30px"
        marginTop="50px"
      >
        <Typography fontWeight="bold">Serviço oferecido por: </Typography>
        <Box>
          <ListItem disablePadding>
            <ListItemButton onClick={handleStoreClick}>
              <ListItemAvatar>
                <Avatar src={selectedServiceStore.Store.imagePreview} />
              </ListItemAvatar>
              <ListItemText
                primary={selectedServiceStore.Store.name}
                secondary={selectedServiceStore.Store.listTags}
              />
            </ListItemButton>
          </ListItem>
        </Box>
      </Box>

      <Box width="100%">
        <Divider />
      </Box>

      <Box
        marginTop="30px "
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h5" fontWeight="bold">
          Avaliações dos usuários
        </Typography>
      </Box>

      <Box>
        {ratings.length > 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            gap={4}
            margin="0px 30px 50px 30px"
          >
            {ratings.map((rating, index) => (
              <Box key={index} display="flex" flexDirection="column" gap={1}>
                <Box display="flex" gap={1} alignItems="center">
                  <Avatar src={rating.User.imagePreview} />

                  <Box display="flex" flexDirection="column">
                    <Typography fontWeight="bold">
                      {rating.User.name}
                    </Typography>
                    <Rating value={rating.rating} readOnly />
                  </Box>
                </Box>
                <Box>
                  <Typography>{rating.comment}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            margin="20px 0px 50px 0px"
          >
            <Typography color="text.secondary">
              Ainda não há avaliações para este serviço.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  backButton: {
    margin: "5px",
  },

  backIcon: {
    width: "30px",
    height: "30px",
  },
};
