import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  ListItemText,
  Typography,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import CEODrawer from "../../../../../components/pet-beauty/CEODrawer";
import { useAuth } from "../../../../../context/AuthProvider/useAuth";
import storeApi from "../../../../../services/api/Store";
import { IStore } from "../../../../../services/api/Store/types";
import {
  generalBrightGrey,
} from "../../../../../styles/colors";
import { cleanLocalStorage } from "../../../../../utils/cleanLocalStorage";
import { cleanStoreFirebaseFolder } from "../../../../../utils/firebase";
import defaultStoreImage from "../../../../../assets/svg/defaultStore.svg";

// void navigator.serviceWorker
//   .register("service-worker.js")
//   .then(async (serviceWorker) => {
//     let subscription = await serviceWorker.pushManager.getSubscription();

//     if (!subscription) {
//       const publicKeyRes = await notificationApi.getPublicKey();

//       subscription = await serviceWorker.pushManager.subscribe({
//         userVisibleOnly: true,
//         applicationServerKey: publicKeyRes.public_key,
//       });
//     }

//     const subscriptionRes = await notificationApi.createSubscription({
//       endpoint: subscription.endpoint,
//       key_auth: subscription.toJSON().keys.auth,
//       key_p256dh: subscription.toJSON().keys.p256dh,
//     });

//     console.log(subscriptionRes);
//   });

const Stores = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  cleanLocalStorage();

  const [stores, setStores] = useState<IStore[]>([]);
  const [areStoresLoading, setAreStoresLoading] = useState(true);

  const fetchStores = async () => {
    try {
      const allStores = await storeApi.getManagerStores(auth.user.id);
      setStores(allStores);
      setAreStoresLoading(false);
    } catch (error) {
      console.warn("Error getting all stores: ", error);
    }
  };

  useEffect(() => {
    fetchStores();
  }, [auth]);

  const handleSelectStore = (store: IStore): void => {
    localStorage.setItem("selectedStore", JSON.stringify(store));
  };

  const handleSeeStoreDetailsClick = (store: IStore): void => {
    handleSelectStore(store);
    navigate("/stores/details");
  };

  const handleEditStoreClick = (store: IStore): void => {
    handleSelectStore(store);
    navigate("/stores/edit");
  };

  const handleDeleteStore = async (store: IStore) => {
    const wantToDelete: boolean = window.confirm(
      `Tem certeza que deseja excluir ${store.name}?`
    );

    if (wantToDelete) {
      try {
        cleanStoreFirebaseFolder(store.name);
        await storeApi.deleteStore(store.id);
        fetchStores();
      } catch (error) {
        console.error("Error deleting the store: ", error);
      }
    }
  };

  const renderStoresPageContent = (
    <Box>
      <CEOInterfaceHeader
        title="Seus estabelecimentos"
        buttonIcon={<AddBusinessIcon />}
        buttonLabel="Adicionar"
        buttonAction={() => {
          navigate("/stores/add");
        }}
      />
      <Box width="100%">
        {!areStoresLoading ? (
          <TableContainer sx={styles.storeItemContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Estabelecimento</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stores.length !== 0 ? (
                  stores.map((store) => (
                    <TableRow
                      key={store.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        sx={styles.storeItemContent}
                        align="left"
                      >
                        <Avatar
                          src={store.imagePreview[0] ?? defaultStoreImage}
                          sx={styles.avatarStyle}
                        />

                        <ListItemText
                          primary={`${store.name}`}
                          secondary={`CNPJ: ${store?.CNPJ}`}
                        />
                      </TableCell>

                      <TableCell align="right">
                        <Tooltip title="Detalhes">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              handleSeeStoreDetailsClick(store);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Editar">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              handleEditStoreClick(store);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Excluir">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              handleDeleteStore(store);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography color="text.secondary" variant="body2">
                        Ainda não há estabelecimentos cadastrados
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={styles.loadingContainer}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderStoresPageContent} />
    </Box>
  );
};

export default Stores;

const styles = {
  storeItemContainer: {
    margin: "30px 0px",
    width: "100%",
    border: `1px solid ${generalBrightGrey}`,
    borderRadius: "15px",
  },

  storeItemContent: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },

  avatarStyle: {
    width: "80px",
    height: "80px",
    borderRadius: 0,
  },

  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },
};
