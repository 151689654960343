import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { useNavigate } from "react-router-dom";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import { Button, Tooltip, Typography } from "@mui/material";
import {
  generalBlack,
  petPrimaryGreen,
  dmPrimaryOrange,
  petSecondaryGreen,
  dmYellow,
} from "../../../../styles/colors";
import { UserRole } from "../../../../types/types";
import {
  CURRENT_SUBDOMAIN,
  DEMANDORIA_SUBDOMAINS,
} from "../../../../utils/stringUtils";

interface MainNavBarProps {
  isUserComercial?: boolean;
  hideMidButtons?: boolean;
}

export default function ClientNavbar(props: MainNavBarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const auth = useAuth();
  const navigate = useNavigate();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMyProfileClick = () => {
    navigate("/user/details");
  };

  const handleLogoutClick = () => {
    const confirm: boolean = window.confirm("Tem certeza que deseja sair?");

    if (confirm) {
      handleMenuClose();
      auth.logout();
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMyProfileClick();
        }}
        sx={{ display: "flex", gap: "10px" }}
      >
        <PersonIcon />
        Minha conta
      </MenuItem>
      <hr />
      {!props && (
        <MenuItem onClick={handleMenuClose}>Histórico de serviços</MenuItem>
      )}
      <MenuItem
        onClick={() => {
          handleLogoutClick();
        }}
        sx={{ display: "flex", gap: "10px", color: "red" }}
      >
        <LogoutIcon />
        Sair
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => {
          navigate("/my-appointments");
        }}
      >
        <IconButton size="large">
          <Badge>
            <CalendarMonthIcon sx={{ color: generalBlack }} />
          </Badge>
        </IconButton>
        <p>Agendamentos</p>
      </MenuItem>
      {!props.isUserComercial && (
        <MenuItem
          onClick={() => {
            navigate("/cart");
          }}
        >
          <IconButton size="large">
            <Badge>
              <ShoppingCartIcon sx={{ color: generalBlack }} />
            </Badge>
          </IconButton>
          <p>Carrinho de compras</p>
        </MenuItem>
      )}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton size="large">
          <AccountCircle sx={{ color: generalBlack }} />
        </IconButton>
        <p>Meu perfil</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "secondary.main",
          boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          width: "100vw",
        }}
      >
        <Toolbar>
          <Button
            sx={{ textTransform: "none", color: generalBlack }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              auth.user.role === UserRole.CLIENT
                ? navigate("/home")
                : CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0] ||
                    CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1]
                  ? navigate("/stores")
                  : navigate("/agencies");
            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ "@media(max-width:500px)": { display: "none" } }}
            >
              Demandoria
            </Typography>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ "@media(min-width:501px)": { display: "none" } }}
            >
              D
            </Typography>
          </Button>

          <Box sx={{ flexGrow: 1 }} />
          {!props.hideMidButtons ? (
            <>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  navigate("/search");
                }}
                startIcon={<ManageSearchIcon />}
                sx={{
                  backgroundColor: "primary.main",

                  textTransform: "none",
                }}
              >
                Busca avançada
              </Button>
            </>
          ) : (
            <></>
          )}

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Tooltip title="Agendamentos">
              <IconButton
                size="large"
                onClick={() => {
                  navigate("/my-appointments");
                }}
              >
                <Badge>
                  <CalendarMonthIcon sx={{ color: generalBlack }} />
                </Badge>
              </IconButton>
            </Tooltip>
            {!props.isUserComercial && (
              <Tooltip title="Carrinho de compras">
                <IconButton
                  size="large"
                  onClick={() => {
                    navigate("/cart");
                  }}
                >
                  <Badge>
                    <ShoppingCartIcon sx={{ color: generalBlack }} />
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Minha conta">
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
              >
                <AccountCircle sx={{ color: generalBlack }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon sx={{ color: generalBlack }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
