import { createTheme } from "@mui/material/styles";
import {
  generalBlack,
  generalDarkGrey,
  petPrimaryGreen,
  petSecondaryGreen,
  dmYellow,
  beautyPrimaryPurple,
  beautySecondaryPurple,
  dmBrighterOrange,
} from "./colors";
import { ptBR } from "@mui/x-date-pickers/locales";

export const beautyTheme = createTheme(
  {
    palette: {
      primary: {
        main: beautyPrimaryPurple,
      },
      secondary: {
        main: beautySecondaryPurple,
      },
      text: {
        primary: generalBlack,
        secondary: generalDarkGrey,
      },
    },
  },
  ptBR
);

export const petTheme = createTheme(
  {
    palette: {
      primary: {
        main: petPrimaryGreen,
      },
      secondary: {
        main: petSecondaryGreen,
      },
      text: {
        primary: generalBlack,
        secondary: generalDarkGrey,
      },
    },
  },
  ptBR
);

export const turismTheme = createTheme(
  {
    palette: {
      primary: {
        main: dmBrighterOrange,
      },
      secondary: {
        main: dmYellow,
      },
      text: {
        primary: generalBlack,
        secondary: generalDarkGrey,
      },
    },
  },
  ptBR
);
