import { HashRouter, Routes, Route } from "react-router-dom";
import AccountDetails from "../pages/common/AccountDetails";
import PasswordRecovery from "../pages/common/PasswordRecovery";
import Register from "../pages/common/Register";
import CEOAppointments from "../pages/petAndBeauty/CEOPages/CEOAppointments";
import AddEmployee from "../pages/petAndBeauty/CEOPages/EmployeesPages/AddEmployee";
import EditEmployee from "../pages/petAndBeauty/CEOPages/EmployeesPages/EditEmployee";
import EmployeeView from "../pages/petAndBeauty/CEOPages/EmployeesPages/EmployeeView";
import Employees from "../pages/petAndBeauty/CEOPages/EmployeesPages/Employees";
import MyWorkscales from "../pages/petAndBeauty/CEOPages/MyWorkscales";
import AddService from "../pages/petAndBeauty/CEOPages/ServicesPages/AddService";
import EditService from "../pages/petAndBeauty/CEOPages/ServicesPages/EditService";
import ServiceView from "../pages/petAndBeauty/CEOPages/ServicesPages/ServiceView";
import ServicesPage from "../pages/petAndBeauty/CEOPages/ServicesPages/ServicesPage";
import AddStore from "../pages/petAndBeauty/CEOPages/StoresPages/AddStore";
import EditStore from "../pages/petAndBeauty/CEOPages/StoresPages/EditStore";
import StoreView from "../pages/petAndBeauty/CEOPages/StoresPages/StoreView";
import Stores from "../pages/petAndBeauty/CEOPages/StoresPages/Stores";
import CartPayment from "../pages/petAndBeauty/ClientPages/CartPayment";
import ClientAppointments from "../pages/petAndBeauty/ClientPages/ClientAppointments";
import { EmployeePage } from "../pages/petAndBeauty/ClientPages/EmployeePage";
import { ScheduilingPage } from "../pages/petAndBeauty/ClientPages/ScheduilingPage";
import { SearchPage } from "../pages/petAndBeauty/ClientPages/SearchPage";
import { ServicePage } from "../pages/petAndBeauty/ClientPages/ServicePage";
import { StorePage } from "../pages/petAndBeauty/ClientPages/StorePage";
import PetLandingPage from "../pages/petAndBeauty/LandingPages/pet";
import AddAgency from "../pages/turism/CEOPages/Agencies/AddAgency";
import Agencies from "../pages/turism/CEOPages/Agencies/Agencies";
import AgencyView from "../pages/turism/CEOPages/Agencies/AgencyView";
import EditAgency from "../pages/turism/CEOPages/Agencies/EditAgency";
import AddExcursion from "../pages/turism/CEOPages/Excursions/AddExcursion";
import EditExcursion from "../pages/turism/CEOPages/Excursions/EditExcursion";
import ExcursionDetails from "../pages/turism/CEOPages/Excursions/ExcursionDetails";
import Excursions from "../pages/turism/CEOPages/Excursions/Excursions";
import {
  getDemandoriaSubdomain,
  DEMANDORIA_SUBDOMAINS,
} from "../utils/stringUtils";
import { Home } from "../pages/petAndBeauty/ClientPages/Home";
import ShoppingCart from "../pages/petAndBeauty/ClientPages/ShoppingCart";
import Login from "../pages/common/Login";
import TurismLandingPage from "../pages/turism/LandingPage";
import BeautyLandingPage from "../pages/petAndBeauty/LandingPages/beauty";
import GeneralLandingPage from "../pages/common/GeneralLandingPage";

const petAndBeautyCommonRoutes = (
  <>
    <Route path="/stores" element={<Stores />} />
    <Route path="/stores/add" element={<AddStore />} />
    <Route path="/stores/edit" element={<EditStore />} />
    <Route path="/stores/details" element={<StoreView />} />
    <Route path="/services" element={<ServicesPage />} />
    <Route path="/services/add" element={<AddService />} />
    <Route path="/services/edit" element={<EditService />} />
    <Route path="/services/details" element={<ServiceView />} />
    <Route path="/employees" element={<Employees />} />
    <Route path="/employees/add" element={<AddEmployee />} />
    <Route path="/employees/edit" element={<EditEmployee />} />
    <Route path="/employees/details" element={<EmployeeView />} />
    <Route path="/my-workscales" element={<MyWorkscales />} />
    <Route path="/appointments" element={<CEOAppointments />} />
    <Route path="/my-appointments" element={<ClientAppointments />} />
    <Route path="/employee" element={<EmployeePage />} />
    <Route path="/scheduiling" element={<ScheduilingPage />} />
    <Route path="/cart/payment" element={<CartPayment />} />
  </>
);

const turismRoutes = (
  <>
    <Route path="/agencies" element={<Agencies />} />
    <Route path="/agencies/add" element={<AddAgency />} />
    <Route path="/agencies/edit" element={<EditAgency />} />
    <Route path="/agencies/details" element={<AgencyView />} />
    <Route path="/excursions" element={<Excursions />} />
    <Route path="/excursions/add" element={<AddExcursion />} />
    <Route path="/excursions/edit" element={<EditExcursion />} />
    <Route path="/excursions/details" element={<ExcursionDetails />} />
  </>
);

const AppRoutes = () => {
  const subdomain = getDemandoriaSubdomain();

  return (
    <HashRouter>
      <Routes>
        <Route path="/user/details" element={<AccountDetails />} />
        <Route path="/home" element={<Home />} />
        <Route path="/service" element={<ServicePage />} />
        <Route path="/store" element={<StorePage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/cart" element={<ShoppingCart />} />
        <Route path="/cart/payment" element={<CartPayment />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />

        {subdomain === DEMANDORIA_SUBDOMAINS[0] ? (
          <>
            {petAndBeautyCommonRoutes}
            <Route path="/" element={<BeautyLandingPage />} />
          </>
        ) : subdomain === DEMANDORIA_SUBDOMAINS[1] ? (
          <>
            {petAndBeautyCommonRoutes}
            <Route path="/" element={<PetLandingPage />} />
          </>
        ) : subdomain === DEMANDORIA_SUBDOMAINS[2] ? (
          <>
            {turismRoutes}
            <Route path="/" element={<TurismLandingPage />} />
          </>
        ) : null}

        <Route path="*" element={<GeneralLandingPage />} />
      </Routes>
    </HashRouter>
  );
};

export default AppRoutes;
