import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { useEffect, useState } from "react";
import L from "leaflet";
import { MapCard } from "../../../../components/general/MapHoverCard";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import storeApi from "../../../../services/api/Store";
import { IStore } from "../../../../services/api/Store/types";
import {
  generalWhite,
  generalBrightGrey,
  dmYellow,
} from "../../../../styles/colors";
import calculateDistanceBetweenCoordinates from "../../../../utils/calculateDistanceBetweenCoordinates";
import { cleanLocalStorage } from "../../../../utils/cleanLocalStorage";
import MarkerClusterGroup from "react-leaflet-cluster";
import {
  AddressProps,
  getCoordinatesByAddress,
} from "../../../../hooks/getCoodinatesByAddress";
import { useAuth } from "../../../../context/AuthProvider/useAuth";

function MapCenterHandler(props) {
  const { mapCenter } = props;
  const map = useMap();
  useEffect(() => {
    if (mapCenter) {
      map.setView(L.latLng(mapCenter[0], mapCenter[1]), map.getZoom(), {
        animate: true,
      });
    }
  }, [mapCenter]);

  return null;
}

export const Home = () => {
  cleanLocalStorage();

  const auth = useAuth();

  const [map, setMap] = useState(null);

  const [nearbyStores, setNearbyStores] = useState([]);

  const [userCoordinates, setUserCoordinates] = useState<
    [number, number] | null
  >(null);

  const [mapCenter, setMapCenter] = useState<[number, number]>([0, 0]);

  const [mapZoom, setMapZoom] = useState<number>(13);

  const [selectedStoreIndex, setSelectedStoreIndex] = useState(null);

  const getUserLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        onGetUserPositionSuccess,
        onGetUserPositionError
      );
    }
  };

  const onGetUserPositionSuccess = (position) => {
    setUserCoordinates([position.coords.latitude, position.coords.longitude]);
  };

  const onGetUserPositionError = async () => {
    const coordinates = await getCoordinatesByAddress({
      street: auth.user.logradouro,
      number: auth.user.number,
      district: auth.user.district,
      city: auth.user.city,
      state: auth.user.state,
      country: auth.user.country,
    } as AddressProps);

    setUserCoordinates([Number(coordinates[0]), Number(coordinates[1])]);
  };

  useEffect(() => {
    getUserLocation();
  }, [auth.user.id]);

  const fetchNearbyStores = async () => {
    try {
      const storesResponse = await storeApi.getNearbyStores(
        userCoordinates[0],
        userCoordinates[1]
      );

      setNearbyStores(storesResponse);
    } catch (error) {
      console.warn("Error fetching nearby stores: ", error);
    }
  };

  useEffect(() => {
    void fetchNearbyStores();

    if (userCoordinates !== null) setMapCenter(userCoordinates);
  }, [userCoordinates]);

  const handleListStoreClick = (
    latitude: number,
    longitude: number,
    index: number
  ) => {
    map.setView([latitude, longitude], 18);
    setSelectedStoreIndex(index);
  };

  const renderNearbyServicesList = (
    <Box
      height="90vh"
      sx={{
        "@media (max-width: 800px)": {
          minWidth: "100%",
          marginTop: "-350px",
          zIndex: 3,
          backgroundColor: generalWhite,
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
        },
      }}
    >
      <Box
        margin="10px 0px"
        display="flex"
        flexDirection="column"
        gap={1}
        minWidth="300px"
        justifyContent="center"
      >
        <Typography fontWeight="bold" textAlign="center">
          Estabelecimentos próximos
        </Typography>
      </Box>
      <Box
        width="300px"
        maxHeight="82vh"
        sx={{
          overflowY: "auto",
          width: "100%",
          padding: "5px 0px",
          borderTop: `1px solid ${generalBrightGrey}`,
        }}
      >
        {nearbyStores.length > 0 ? (
          <Box>
            <List sx={{ width: "100%", p: 0, m: 0 }}>
              {nearbyStores.map((store: IStore, index) => (
                <ListItem key={`${store.id}_item`} sx={{ padding: "2px 5px" }}>
                  <ListItemButton
                    onClick={() => {
                      handleListStoreClick(store.lat, store.lng, index);
                    }}
                    sx={{
                      backgroundColor:
                        selectedStoreIndex === index
                          ? "secondary.main"
                          : generalWhite,
                      borderRadius:
                        selectedStoreIndex === index ? "8px" : "0px",
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar src={store.imagePreview[0]} />
                      <ListItemText
                        primary={store.name}
                        secondary={`aprox. ${calculateDistanceBetweenCoordinates(
                          store.lat,
                          store.lng,
                          userCoordinates[0],
                          userCoordinates[1]
                        ).toFixed(2)} km`}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        ) : (
          <Box
            display="flex"
            height="90vh"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress
              sx={{
                "@media (max-width: 800px)": {
                  marginTop: "-450px",
                },
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );

  const renderMap = (
    <MapContainer
      center={mapCenter}
      zoom={mapZoom}
      style={{
        width: "100%",
        zIndex: 1,
        height: "90vh",
      }}
      easeLinearity={0.35}
      ref={setMap}
      maxZoom={20}
    >
      <MarkerClusterGroup chunkedLoading>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {nearbyStores.map((store: IStore) => (
          <Marker
            key={store.id}
            position={[store.lat, store.lng]}
            title={store.name}
          >
            <Popup>
              <MapCard
                imageUrl={store.imagePreview[0]}
                primaryText={`${store.name}`}
                secondaryText={`${store.district}, ${
                  store.city
                } (${calculateDistanceBetweenCoordinates(
                  store.lat,
                  store.lng,
                  userCoordinates[0],
                  userCoordinates[1]
                ).toFixed(2)} km)`}
                selectedStore={store}
              />
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
      <MapCenterHandler mapCenter={mapCenter} />
    </MapContainer>
  );

  return (
    <Box>
      <Box sx={{ overflowX: "hidden", overflowY: "hidden" }}>
        <ClientNavbar />
        <Box
          display="flex"
          sx={{
            "@media (max-width: 800px)": {
              flexDirection: "column-reverse",
            },
          }}
        >
          {renderNearbyServicesList}
          {renderMap}
        </Box>
      </Box>
    </Box>
  );
};
