import Box from "@mui/material/Box";
import { useState } from "react";
import { IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  dmBrighterOrange,
  generalDarkGrey,
  generalWhite,
} from "../../../styles/colors";

interface ImageCarouselProps {
  imageUrls: string[];
}

const baseCarouselButtonStyles = {
  position: "absolute",
  top: 0,
  bottom: 0,
  padding: "1rem",
  borderRadius: 0,
  display: "block",
  ":hover": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
};

const leftButtonStyles = {
  ...baseCarouselButtonStyles,
  left: 0,
};

const rightButtonStyles = {
  ...baseCarouselButtonStyles,
  right: 0,
};

const carouselStepsBoxStyles = {
  position: "absolute",
  bottom: 0,
};

export const ImageCarousel = (props: ImageCarouselProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextImageClick = () => {
    currentImageIndex === props.imageUrls.length - 1
      ? setCurrentImageIndex(0)
      : setCurrentImageIndex((prevImageIndex) => prevImageIndex + 1);
  };

  const handlePreviousImageClick = () => {
    currentImageIndex === 0
      ? setCurrentImageIndex(props.imageUrls.length - 1)
      : setCurrentImageIndex((prevImageIndex) => prevImageIndex - 1);
  };

  return props.imageUrls.length > 0 ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        aspectRatio: "6/3",
        position: "relative",
      }}
    >
      <img
        src={props.imageUrls[currentImageIndex]}
        alt={`Image ${currentImageIndex + 1}`}
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
          display: "block",
        }}
      />
      <Box
        width="100%"
        justifyContent="center"
        gap={1}
        sx={{
          ...carouselStepsBoxStyles,
          display: props.imageUrls.length > 1 ? "flex" : "none",
        }}
      >
        {props.imageUrls.map((_, index) => (
          <IconButton
            onClick={() => setCurrentImageIndex(index)}
            sx={{
              padding: 0,
              margin: 0,
              color:
                currentImageIndex === index
                  ? "secondary.main"
                  : generalDarkGrey,
              fontSize: "30px",
            }}
            disableRipple
          >
            •
          </IconButton>
        ))}
      </Box>
      <IconButton
        onClick={handlePreviousImageClick}
        sx={{
          ...leftButtonStyles,
          display: props.imageUrls.length > 1 ? "block" : "none",
        }}
        disableRipple
      >
        <ChevronLeftIcon
          sx={{
            backgroundColor: "primary.main",
            color: generalWhite,
            borderRadius: "100%",
          }}
        />
      </IconButton>
      <IconButton
        onClick={handleNextImageClick}
        sx={{
          ...rightButtonStyles,
          display: props.imageUrls.length > 1 ? "block" : "none",
        }}
        disableRipple
      >
        <ChevronRightIcon
          sx={{
            backgroundColor: "primary.main",
            color: generalWhite,
            borderRadius: "100%",
          }}
        />
      </IconButton>
    </Box>
  ) : (
    <></>
  );
};
