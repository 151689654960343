import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CEODrawer from "../../../../../components/comercial/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { useEffect, useRef, useState } from "react";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { useNavigate } from "react-router-dom";
import { ExcursionStatus, FOOD_SERVICES } from "../../../../../types/types";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ImageGalleryHeader from "../../../../../components/comercial/ImageGallery/ImageGalleryHeader";
import ImageGallery from "../../../../../components/comercial/ImageGallery";
import dayjs, { Dayjs } from "dayjs";
import excursionApi from "../../../../../services/api/Excursion";
import IExcursion from "../../../../../services/api/Excursion/IExcursion";
import { uploadImagesToFirebase } from "../../../../../utils/firebase";
import { useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import printResponseError from "../../../../../utils/printResponseError";
import { yupResolver } from "@hookform/resolvers/yup";
import { addAndEditExcursionSchema, isPriceInvalid } from "../excursionValidationSchemas";
import { generalWhite } from "../../../../../styles/colors";
import PriceInput from "../../../../../components/general/PriceInput";

const AddExcursion = () => {
  const navigate = useNavigate();

  const selectedAgency = JSON.parse(localStorage.getItem("selectedStore"));

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [router, setRouter] = useState([]);

  const [images, setImages] = useState<HTMLCanvasElement[]>([]);

  const [originalImages, setOriginalImages] = useState<HTMLCanvasElement[]>([]);
  const [previewImages, setPreviewImages] = useState<HTMLCanvasElement[]>([]);

  const originalImageUrls = [];
  const previewImageUrls = [];

  const titleRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const agentRef = useRef<HTMLInputElement>(null);
  const [price, setPrice] = useState<number | null>(null);
  const categoryRef = useRef<HTMLInputElement>(null);
  const restrictionRef = useRef<HTMLInputElement>(null);

  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const maxVacanciesRef = useRef<HTMLInputElement>(null);
  const minVacanciesRef = useRef<HTMLInputElement>(null);
  const transportServiceRef = useRef<HTMLInputElement>(null);
  const accommodationServiceRef = useRef<HTMLInputElement>(null);
  const foodServiceRef = useRef<HTMLSelectElement>(null);
  const groupLinkRef = useRef<HTMLInputElement>(null);
  const excursionContractRef = useRef<HTMLInputElement>(null);

  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);
  const regionRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLInputElement>(null);
  const routerRef = useRef<HTMLInputElement>(null);

  const [startDateIsBeforeEndDateError, setStartDateIsBeforeEndDateError] =
    useState(false);

  const [submittedOnce, setSubmittedOnce] = useState(false);

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addAndEditExcursionSchema) });

  const handleExcursionCreation = async () => {
    //if (!submittedOnce) setSubmittedOnce(true);

    if (price > 0 && price) {
      setSubmitLoading(true);
      try {
        const newExcursion: IExcursion = {
          storeId: selectedAgency.id,
          title: titleRef.current.value,
          description: descriptionRef.current.value,
          restriction: restrictionRef.current.value,
          accommodationService: accommodationServiceRef.current.value,
          transportService: transportServiceRef.current.value,
          foodService: foodServiceRef.current.value,
          price: price,
          agent: agentRef.current.value,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          mainCategory: categoryRef.current.value,
          router: router,
          country: countryRef.current.value,
          region: regionRef.current.value,
          state: stateRef.current.value,
          city: cityRef.current.value,
          maxVacancies: Number(maxVacanciesRef.current.value),
          minVacancies: Number(minVacanciesRef.current.value),
          groupLink: groupLinkRef.current.value,
          excursionContract: excursionContractRef.current.value,
          excursionStatus: ExcursionStatus.ACTIVE,
          listTags: [],
          imageOriginal: [],
          imagePreview: [],
        };

        const newExcursionResponse =
          await excursionApi.createExcursion(newExcursion);

        if (images.length > 0) {
          const originalUuids = await uploadImagesToFirebase(
            originalImages,
            originalImageUrls,
            `images/stores/${selectedAgency.name}_${selectedAgency.id}
          /excursions/${newExcursionResponse.title}_${newExcursionResponse.id}`,
            "_original",
            []
          );

          await uploadImagesToFirebase(
            previewImages,
            previewImageUrls,
            `images/stores/${selectedAgency.name}_${selectedAgency.id}
          /excursions/${newExcursionResponse.title}_${newExcursionResponse.id}`,
            "_preview",
            originalUuids
          );

          newExcursion.imageOriginal = originalImageUrls;
          newExcursion.imagePreview = previewImageUrls;

          await excursionApi.updateExcursion(
            newExcursionResponse.id,
            newExcursion
          );
        }
        navigate("/excursions");
      } catch (error) {
        printResponseError("Error adding an excursion: ", error);
      }

      setSubmitLoading(false);
    }
  };

  const handleAddRoute = () => {
    if (routerRef.current.value !== "")
      setRouter((previousRouter) => [
        ...previousRouter,
        routerRef.current.value,
      ]);

    routerRef.current.value = "";
  };

  const handleDeleteRoute = (index: number) => {
    setRouter((previousRouter) => previousRouter.filter((_, i) => i !== index));
  };

  useEffect(() => {
    endDate.isBefore(startDate)
      ? setStartDateIsBeforeEndDateError(true)
      : setStartDateIsBeforeEndDateError(false);
  }, [startDate, endDate]);

  const renderExcursionData = (
    <>
      <CEOInterfaceHeader title="Dados gerais da excursão" />
      <Box
        display="flex"
        gap={2}
        margin="25px"
        sx={{ "@media (max-width:900px)": { flexDirection: "column" } }}
      >
        <Box display="flex" flexDirection="column" gap={2} width="100%">
          <TextField
            label="Título *"
            size="small"
            fullWidth
            inputRef={titleRef}
            {...register("title")}
            error={!!errors.title}
            helperText={errors.title?.message}
            autoComplete="off"
          />
          <TextField
            label="Descrição"
            size="small"
            fullWidth
            inputRef={descriptionRef}
            rows={6}
            multiline
            placeholder="Descreva os detalhes da sua excursão..."
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={2} width="100%">
          <TextField
            label="Agente *"
            size="small"
            fullWidth
            inputRef={agentRef}
            {...register("agent")}
            error={!!errors.agent}
            helperText={errors.agent?.message}
            autoComplete="off"
          />
          <PriceInput
            label="Preço"
            size="small"
            placeholder="Ex.: R$ 500,00"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
            error={isPriceInvalid(submittedOnce, price)}
            autoComplete="off"
            helperText={
              isPriceInvalid(submittedOnce, price)
                ? "O preço deve ser maior que R$ 0,00"
                : null
            }
            inputRef={(input) => {
              if (input && isPriceInvalid(submittedOnce, price)) input.focus();
            }}
          />
          <TextField
            label="Categoria *"
            size="small"
            fullWidth
            inputRef={categoryRef}
            {...register("mainCategory")}
            error={!!errors.mainCategory}
            helperText={errors.mainCategory?.message}
            autoComplete="off"
          />
          <TextField
            label="Restrição"
            size="small"
            fullWidth
            inputRef={restrictionRef}
            autoComplete="off"
          />
        </Box>
      </Box>
    </>
  );

  const renderExcursionDetails = (
    <>
      <CEOInterfaceHeader title="Detalhes da excursão" />
      <Box display="flex" flexDirection="column" gap={4} margin="25px">
        <Box
          display="flex"
          gap={4}
          sx={{ "@media (max-width:900px)": { flexDirection: "column" } }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            width="60%"
            sx={{ "@media (max-width:900px)": { width: "100%" } }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <DatePicker
                  label="Data de início *"
                  format="DD/MM/YYYY"
                  disablePast
                  dayOfWeekFormatter={() => {}}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                  value={startDate}
                  onChange={(newStartDate) => setStartDate(newStartDate)}
                />
              </LocalizationProvider>

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <DatePicker
                  label="Data de término *"
                  format="DD/MM/YYYY"
                  disablePast
                  dayOfWeekFormatter={() => {}}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      helperText: startDateIsBeforeEndDateError
                        ? "A data de término deve ser posterior a de início."
                        : null,
                    },
                  }}
                  value={endDate}
                  onChange={(newEndDate) => setEndDate(newEndDate)}
                  minDate={startDate}
                />
              </LocalizationProvider>
            </Box>

            <Box display="flex" alignItems="center" gap={2}>
              <TextField
                label="Mín. de vagas *"
                size="small"
                type="number"
                fullWidth
                inputRef={minVacanciesRef}
                {...register("minVacancies")}
                error={!!errors.minVacancies}
                helperText={errors.minVacancies?.message}
                inputProps={{ min: 1 }}
              />
              <TextField
                label="Máx. de vagas *"
                size="small"
                type="number"
                fullWidth
                inputRef={maxVacanciesRef}
                {...register("maxVacancies")}
                error={!!errors.maxVacancies}
                helperText={errors.maxVacancies?.message}
                inputProps={{ min: 2 }}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            width="40%"
            sx={{ "@media (max-width:900px)": { width: "100%" } }}
          >
            <TextField
              label="Serviço de transporte *"
              size="small"
              fullWidth
              inputRef={transportServiceRef}
              {...register("transportService")}
              error={!!errors.transportService}
              helperText={errors.transportService?.message}
              autoComplete="off"
            />
            <TextField
              label="Serviço de hospedagem *"
              size="small"
              fullWidth
              inputRef={accommodationServiceRef}
              {...register("accommodationService")}
              error={!!errors.accommodationService}
              helperText={errors.accommodationService?.message}
              autoComplete="off"
            />
            <FormControl fullWidth size="small">
              <InputLabel>Serviço de alimentação *</InputLabel>
              <Select
                label="Serviço de alimentação"
                defaultValue={FOOD_SERVICES[0]}
                inputRef={foodServiceRef}
              >
                {FOOD_SERVICES.map((serviceType, index) => (
                  <MenuItem value={FOOD_SERVICES[index]} key={index}>
                    {serviceType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box
          display="flex"
          gap={2}
          sx={{ "@media (max-width:900px)": { flexDirection: "column" } }}
        >
          <TextField
            label="Link do grupo"
            size="small"
            fullWidth
            inputRef={groupLinkRef}
          />
          <TextField
            label="Contrato da excursão"
            size="small"
            fullWidth
            inputRef={excursionContractRef}
          />
        </Box>
      </Box>
    </>
  );

  const renderExcursionGeographicData = (
    <>
      <CEOInterfaceHeader title="Dados geográficos" />
      <Box display="flex" flexDirection="column" gap={2} margin="25px">
        <Box display="flex" gap={2}>
          <TextField
            label="Cidade *"
            size="small"
            fullWidth
            inputRef={cityRef}
            {...register("city")}
            error={!!errors.city}
            helperText={errors.city?.message}
            autoComplete="off"
          />
          <TextField
            label="Estado *"
            size="small"
            fullWidth
            inputRef={stateRef}
            {...register("state")}
            error={!!errors.state}
            helperText={errors.state?.message}
            autoComplete="off"
          />
        </Box>

        <Box display="flex" gap={2}>
          <TextField
            label="Região *"
            size="small"
            fullWidth
            inputRef={regionRef}
            {...register("region")}
            error={!!errors.region}
            helperText={errors.region?.message}
            autoComplete="off"
          />
          <TextField
            label="País *"
            size="small"
            fullWidth
            inputRef={countryRef}
            {...register("country")}
            error={!!errors.country}
            helperText={errors.country?.message}
            autoComplete="off"
          />
        </Box>

        <Box>
          <Box display="flex">
            <TextField
              label="Adicione lugares à rota"
              size="small"
              fullWidth
              inputRef={routerRef}
            />
            <Button
              variant="contained"
              disableElevation
              sx={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                color: generalWhite,
              }}
              onClick={handleAddRoute}
            >
              <AddLocationAltIcon />
            </Button>
          </Box>
          <Box margin="20px 0px">
            {router.map((route, index) => (
              <Chip
                label={route}
                onDelete={() => handleDeleteRoute(index)}
                icon={<FmdGoodIcon />}
                sx={{ margin: "5px 0px", fontSize: "18px", padding: "5px" }}
                key={index}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );

  const renderExcursionImageGallery = (
    <>
      <ImageGalleryHeader
        label="Adicionar"
        icon={<AddPhotoAlternateIcon />}
        setImages={setImages}
        originalImages={originalImages}
        previewImages={previewImages}
      />
      <Box display="flex" flexDirection="column" gap={2} margin="25px">
        <ImageGallery
          images={images}
          setImages={setImages}
          setOriginalImages={setOriginalImages}
          setPreviewImages={setPreviewImages}
          interable
          emptyGalleryText={
            <Typography>
              Adicione
              <Typography
                sx={{
                  color: "primary.main",
                  fontWeight: "bold",
                }}
              >
                {" imagens "}
              </Typography>
              da sua excursão!
            </Typography>
          }
        />
      </Box>
    </>
  );

  const renderAddExcursionPageContent = (
    <form onSubmit={onSubmit(handleExcursionCreation)}>
      {renderExcursionData}
      {renderExcursionDetails}
      {renderExcursionGeographicData}
      {renderExcursionImageGallery}
      <Box display="flex" gap={2} margin="20px 0px">
        <PrimaryButton
          label="Confirmar adição"
          icon={<AddIcon />}
          isLoading={submitLoading}
          onClickAction={() => {
            if (!submittedOnce) setSubmittedOnce(true);
          }}
        />
        <SecondaryButton
          label="Cancelar"
          onClickAction={() => navigate("/excursions")}
          isDisabled={submitLoading}
        />
      </Box>
    </form>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderAddExcursionPageContent} />
    </Box>
  );
};

export default AddExcursion;
