import { Box, Rating, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultServiceImage from "../../../../assets/svg/defaultService.svg";
import defaultEmployeeImage from "../../../../assets/svg/defaultUser.svg";
import { IServiceStore } from "../../../../services/api/ServiceStore/types";
import TimeRangeInterpreter from "../../../../components/comercial/TimeRangeInterpreter";
import CustomCard from "../../../../components/general/CustomCard";
import { ImageCarousel } from "../../../../components/general/ImageCarousel";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import employeeApi from "../../../../services/api/Employee";
import { IEmployee } from "../../../../services/api/Employee/types";
import serviceStoreApi from "../../../../services/api/ServiceStore";
import { IStore } from "../../../../services/api/Store/types";
import horizontalScrollbarStyles from "../../../../styles/horizontalScrollbarStyles";
import { generalDarkGrey } from "../../../../styles/colors";
import { priceFormatter } from "../../../../utils/priceFormatter";
import DescriptionWrapper from "../../../../components/general/DescriptionWrapper";

export const StorePage = () => {
  const selectedStore: IStore = JSON.parse(
    localStorage.getItem("selectedStore")
  );

  const navigate = useNavigate();

  const [selectedStoreServiceStores, setSelectedStoreServiceStores] = useState<
    IServiceStore[]
  >([]);

  const [selectedStoreEmployees, setSelectedStoreEmployee] = useState<
    IEmployee[]
  >([]);

  const fetchServiceStoresFromStore = async () => {
    try {
      const serviceStoreRes =
        await serviceStoreApi.getServiceStoresFromStoreName(selectedStore.name);
      setSelectedStoreServiceStores(serviceStoreRes);
    } catch (error) {
      console.warn("Error fetching store's service-stores: ", error);
    }
  };

  const fetchEmployeesFromStore = async () => {
    try {
      const employeeRes = await employeeApi.getEmployeesFromStoreId(
        selectedStore.id
      );
      setSelectedStoreEmployee(employeeRes);
    } catch (error) {
      console.warn("Error fetching store's employees: ", error);
    }
  };

  useEffect(() => {
    fetchServiceStoresFromStore();
  }, []);

  useEffect(() => {
    fetchEmployeesFromStore();
  }, []);

  const handleSelectServiceClick = (serviceStore: IServiceStore) => {
    localStorage.setItem("selectedServiceStore", JSON.stringify(serviceStore));
    navigate("/service");
  };

  const handleSelectEmployeeClick = (employee: IEmployee) => {
    localStorage.setItem("selectedEmployee", JSON.stringify(employee));
    navigate("/employee");
  };

  const renderStoreInfo = (
    <>
      {selectedStore.imageOriginal.length > 0 ? (
        <Box width="100%" height="400px">
          <ImageCarousel imageUrls={selectedStore.imageOriginal} />
        </Box>
      ) : (
        <></>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin="30px 0px"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={1}
          margin="0px 100px"
          sx={{
            "@media (max-width: 800px)": {
              margin: "0px 30px",
            },
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            {selectedStore.name}
          </Typography>
          <Typography
            textAlign="justify"
            fontWeight="bold"
            color="text.secondary"
          >
            {selectedStore.listTags}
          </Typography>
          <Rating name="read-only" value={5} readOnly />
          <Typography
            textAlign="justify"
            color="text.secondary"
            sx={{
              margin: "25px 50px",
              "@media (max-width: 800px)": {
                margin: "25px 0px",
              },
            }}
          >
            <DescriptionWrapper text={selectedStore.description} max={300} />
          </Typography>
        </Box>
      </Box>
    </>
  );

  const renderStoreLocation = (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <MapContainer
        zoom={20}
        center={
          [selectedStore.lat, selectedStore.lng] ?? [-5.18769, -37.344421]
        }
        style={{ width: "90%", height: "400px", borderRadius: "20px" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          key={selectedStore.id}
          position={[selectedStore.lat, selectedStore.lng]}
        />
      </MapContainer>
    </Box>
  );

  const renderStoreContacts = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      margin="50px 0px"
      gap={2}
    >
      <Typography variant="h5" sx={{ m: "10px 0px" }} fontWeight="bold">
        Entre em contato conosco
      </Typography>
      <Box marginBottom="10px" display="flex" flexDirection="column" gap={1}>
        <Box display="flex" gap={2}>
          <LocationOnIcon color="primary" />
          <Typography>
            {selectedStore.logradouro}, {selectedStore.number}
          </Typography>
        </Box>
        <Box display="flex" gap={2}>
          <PhoneIcon color="primary" />
          <Typography>{selectedStore.phone}</Typography>
        </Box>
        <Box display="flex" gap={2}>
          <AlternateEmailIcon color="primary" />
          <Typography>{selectedStore.email}</Typography>
        </Box>
      </Box>
    </Box>
  );

  const renderWorkingScale = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      margin="70px 0px"
      gap={3}
    >
      <Typography variant="h5" fontWeight="bold">
        Horários de funcionamento
      </Typography>
      <TimeRangeInterpreter
        timeMonday={selectedStore.timeMonday}
        timeTuesday={selectedStore.timeTuesday}
        timeWednesday={selectedStore.timeWednesday}
        timeThursday={selectedStore.timeThursday}
        timeFriday={selectedStore.timeFriday}
        timeSaturday={selectedStore.timeSaturday}
        timeSunday={selectedStore.timeSunday}
      />
    </Box>
  );

  const renderOurServices = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={3}
      margin="100px 0px"
    >
      <Typography variant="h5" fontWeight="bold">
        Nossos serviços
      </Typography>
      {selectedStoreServiceStores.length > 0 ? (
        <Box
          display="flex"
          sx={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            width: "90%",
            p: "10px",
          }}
          gap={2}
        >
          {selectedStoreServiceStores.map(
            (serviceStore: IServiceStore, index) => (
              <Box
                key={index}
                onClick={() => {
                  handleSelectServiceClick(serviceStore);
                }}
              >
                <CustomCard
                  photoURL={serviceStore.imagePreview[0]}
                  defaultPhoto={defaultServiceImage}
                  header={`R$ ${priceFormatter(serviceStore.price)}`}
                  primaryText={serviceStore.Service.name}
                  secondaryText={""}
                />
              </Box>
            )
          )}
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          textAlign="center"
        >
          <Typography color="text.secondary">
            Este estabelecimento não possui nenhum serviço cadastrado.
          </Typography>
        </Box>
      )}
    </Box>
  );

  const renderOurEmployees = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={3}
      margin="80px 0px"
    >
      <Typography variant="h5" fontWeight="bold">
        Nossos funcionários
      </Typography>
      {selectedStoreEmployees.length > 0 ? (
        <Box
          display="flex"
          sx={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            width: "90%",
            p: "10px",
          }}
          gap={2}
        >
          {selectedStoreEmployees.map((employee: IEmployee, index) => (
            <Box
              key={index}
              onClick={() => {
                handleSelectEmployeeClick(employee);
              }}
            >
              <CustomCard
                photoURL={employee.User.imagePreview}
                defaultPhoto={defaultEmployeeImage}
                header={""}
                primaryText={employee.User.name}
                secondaryText={""}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          textAlign="center"
        >
          <Typography color="text.secondary">
            Este estabelecimento não possui nenhum serviço cadastrado.
          </Typography>
        </Box>
      )}
    </Box>
  );
  return (
    <Box
      maxWidth="100"
      sx={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        overflowX: "hidden",
      }}
    >
      <ClientNavbar hideMidButtons />

      {renderStoreInfo}
      {renderStoreLocation}
      {renderStoreContacts}
      {renderWorkingScale}
      {renderOurServices}
      {renderOurEmployees}
    </Box>
  );
};
