import {
  Box,
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { formatDateAndTimeDayDateToString } from "../../ClientPages/ScheduilingPage/timeUtils";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import CreateAppointmentModal from "./CreateAppointmentModal";
import { isUserStoreManager } from "./utils";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import CEODrawer from "../../../../components/pet-beauty/CEODrawer";
import { AppointmentDetailsModal } from "../../../../components/general/AppointmentDetailsModal";
import AppointmentStatusChip from "../../../../components/general/AppointmentStatusChip";
import GenericModal from "../../../../components/general/GenericModal";
import appointmentApi from "../../../../services/api/Appointment";
import { IAppointment } from "../../../../services/api/Appointment/types";
import employeeApi from "../../../../services/api/Employee";
import { IEmployee } from "../../../../services/api/Employee/types";
import { generalDarkGrey } from "../../../../styles/colors";
import AppointmentsCalendar from "../../../../components/general/AppointmentsCalendar";
import { getPendentConfirmedCanceledAndFinishedAppointmentDatesFromUserAppointments } from "../../../../components/general/AppointmentsCalendar/filterAppointmentDateStatus";

const CEOAppointments = () => {
  const auth = useAuth();

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  const [userStores, setUserStores] = useState([]);
  const [userEmployees, setUserEmployees] = useState([]);
  const [userAppointments, setUserAppointments] = useState([]);

  const [showingDailyAppointments, setShowingDailyAppointments] = useState(0);

  const [selectedStoreId, setSelectedStoreId] = useState("");

  const [pendentAppointments, setPendentAppointments] = useState([]);
  const [confirmedAppointments, setConfirmedAppointments] = useState([]);
  const [canceledAppointments, setCanceledAppointments] = useState([]);
  const [finishedAppointments, setFinishedAppointments] = useState([]);

  const [selectedUserAppointment, setSelectedUserAppointment] = useState(null);

  const [isAppointmentDetailsModalOpen, setIsAppointmentDetailsModalOpen] =
    useState(false);
  const [isCreateAppointmentModalOpen, setIsCreateAppointmentModalOpen] =
    useState(false);

  const handleOpenAppointmentDetailsModal = (
    selectedAppointment: IAppointment
  ) => {
    setSelectedUserAppointment(selectedAppointment);
    setIsAppointmentDetailsModalOpen(true);
  };

  const handleCloseAppointmentDetailsModal = () => {
    setIsAppointmentDetailsModalOpen(false);
  };

  const handleOpenCreateAppointmentModal = () => {
    setIsCreateAppointmentModalOpen(true);
  };

  const handleCloseCreateAppointmentModal = () => {
    setIsCreateAppointmentModalOpen(false);
  };

  const fetchUserEmployees = async () => {
    try {
      const userEmployeeResponse = await employeeApi.getEmployeeFromUserId(
        auth.user.id
      );

      setUserEmployees(userEmployeeResponse);
      getUserStoresFromUserEmployeeResponse(userEmployeeResponse);
    } catch (error) {
      console.warn("Error fetching user employee: ", error);
    }
  };

  const getUserStoresFromUserEmployeeResponse = (
    userEmployeeResponse: IEmployee[]
  ) => {
    let employeeStores = [{ id: "-", name: "Todos" }];

    for (let i = 0; i < userEmployeeResponse.length; i++) {
      if (!employeeStores.includes(userEmployeeResponse[i].store)) {
        employeeStores.push(userEmployeeResponse[i].store);
      }
    }

    setUserStores(employeeStores);
  };

  const fetchAllUserStoresAppointments = async () => {
    try {
      let allStoreAppointments = [];
      let iterableStoreAppointmentsResponse = null;
      let iterableEmployeeId = "";

      for (let i = 1; i < userStores.length; i++) {
        if (isUserStoreManager(auth.user.id, userStores[i].managerId)) {
          iterableStoreAppointmentsResponse =
            await appointmentApi.getAppointmentsFromStore(userStores[i].id);
        } else {
          iterableEmployeeId = userEmployees.filter(
            (userEmployee) => userEmployee.storeId === userStores[i].id
          )[0].id;

          iterableStoreAppointmentsResponse =
            await appointmentApi.getAppointmentsFromStoreAndEmployee(
              userStores[i].id,
              iterableEmployeeId
            );
        }
        allStoreAppointments = allStoreAppointments.concat(
          iterableStoreAppointmentsResponse
        );

        iterableStoreAppointmentsResponse =
          await appointmentApi.getAppointmentsFromStore(userStores[i].id);
        allStoreAppointments = allStoreAppointments.concat(
          iterableStoreAppointmentsResponse
        );
      }

      setUserAppointments(allStoreAppointments);

      if (selectedStoreId === "" && allStoreAppointments.length > 0)
        setSelectedStoreId("-");
    } catch (error) {
      console.warn("Error fetching all user stores appointments: ", error);
    }
  };

  const filterAppointments = (
    appointment: IAppointment,
    selectedDate: Dayjs | null,
    selectedStoreId: string
  ) => {
    const appointmentDateMatchWithSelectedDate =
      appointment.date.split("T")[0] === selectedDate?.format("YYYY-MM-DD");
    const showAllStoresOrFilterByName =
      selectedStoreId === "-" ||
      appointment.serviceEmployee.Employee.store.id === selectedStoreId;

    return appointmentDateMatchWithSelectedDate && showAllStoresOrFilterByName;
  };

  useEffect(() => {
    fetchUserEmployees();
  }, [auth]);

  useEffect(() => {
    fetchAllUserStoresAppointments();

    if (userStores.length > 0) setSelectedStoreId("-");
  }, [userStores]);

  useEffect(() => {
    let filteredAppointmentsQuantity = 0;

    userAppointments.forEach((appointment) => {
      if (filterAppointments(appointment, selectedDate, selectedStoreId))
        filteredAppointmentsQuantity++;
    });
    setShowingDailyAppointments(filteredAppointmentsQuantity);
  }, [selectedDate, selectedStoreId]);

  useEffect(() => {
    if (userAppointments.length > 0) {
      let filteredAppointments = [];

      selectedStoreId !== "-"
        ? (filteredAppointments = userAppointments.filter(
            (appointment) =>
              appointment.serviceEmployee.Employee.storeId === selectedStoreId
          ))
        : (filteredAppointments = userAppointments);

      getPendentConfirmedCanceledAndFinishedAppointmentDatesFromUserAppointments(
        filteredAppointments,
        setPendentAppointments,
        setConfirmedAppointments,
        setFinishedAppointments,
        setCanceledAppointments
      );
    }
  }, [userAppointments, selectedStoreId]);

  const renderAppointmentsCalendar = (
    <>
      <AppointmentsCalendar
        pendentAppointmentDates={pendentAppointments}
        confirmedAppointmentDates={confirmedAppointments}
        canceledAppointmentDates={canceledAppointments}
        finishedAppointementDates={finishedAppointments}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </>
  );

  const renderAppointmentsList = (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <Box display="flex" flexDirection="column" gap={3} width="100%">
        <Button
          variant="contained"
          disableElevation
          fullWidth
          sx={{ textTransform: "none" }}
          startIcon={<AddAlertIcon />}
          onClick={handleOpenCreateAppointmentModal}
        >
          Criar agendamento ({selectedDate?.format("DD/MM/YYYY")})
        </Button>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Typography variant="h6">
            {selectedDate?.format("DD/MM/YYYY")}
          </Typography>
          <Typography color={generalDarkGrey} variant="body2">
            ({showingDailyAppointments}{" "}
            {showingDailyAppointments !== 1 ? "agendamentos" : "agendamento"})
          </Typography>
        </Box>
      </Box>

      {userAppointments.some(
        (appointment) =>
          appointment.date.split("T")[0] === selectedDate?.format("YYYY-MM-DD")
      ) ? (
        <List
          sx={{
            overflowY: "auto",
            boxShadow:
              "inset 0px 15px 10px -10px rgba(0, 0, 0, 0.15), inset 0px -15px 10px -10px rgba(0, 0, 0, 0.15)",
            "@media (max-width: 900px)": {
              width: "100%",
            },
            height: "342px",
            borderRadius: "10px",
          }}
          disablePadding
        >
          {userAppointments.map((appointment, index) =>
            filterAppointments(appointment, selectedDate, selectedStoreId) ? (
              <Box>
                <ListItemButton
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                  onClick={() =>
                    handleOpenAppointmentDetailsModal(userAppointments[index])
                  }
                >
                  <Box>
                    <ListItemText
                      primary={`${userAppointments[index].serviceEmployee.ServiceStore.Service.name}`}
                      secondary={`${userAppointments[index].serviceEmployee.Employee.store.name}`}
                      primaryTypographyProps={{ fontWeight: "bold" }}
                      secondaryTypographyProps={{
                        display: selectedStoreId === "-" ? "block" : "none",
                      }}
                    />
                    <Typography
                      variant="body2"
                      color={generalDarkGrey}
                    >{`${formatDateAndTimeDayDateToString(
                      userAppointments[index].date
                    )}`}</Typography>
                    <Typography
                      variant="body2"
                      color={generalDarkGrey}
                    >{`Cliente: ${
                      userAppointments[index].clientName ?? "-"
                    }`}</Typography>
                    {!isUserStoreManager(
                      auth.user.id,
                      userAppointments[index].serviceEmployee.Employee.store
                        .managerId
                    ) ? (
                      <></>
                    ) : (
                      <Typography
                        variant="body2"
                        color={generalDarkGrey}
                      >{`Funcionário: ${
                        userAppointments[index].serviceEmployee.Employee.User
                          .id === auth.user.id
                          ? `${auth.user.name} (você)`
                          : userAppointments[index].serviceEmployee.Employee
                              .User.name
                      }`}</Typography>
                    )}
                  </Box>
                  <AppointmentStatusChip
                    status={userAppointments[index].status}
                  />
                </ListItemButton>
              </Box>
            ) : (
              <></>
            )
          )}
        </List>
      ) : (
        <Box>
          <Typography color={generalDarkGrey}>
            Nenhum agendamento para esta data.
          </Typography>
        </Box>
      )}
    </Box>
  );

  const renderAppointmentsSection = (
    <Box width="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={3}
        marginBottom="30px"
      >
        <Typography variant="h6" fontWeight="bold">
          Agendamentos
        </Typography>
        <Box display="flex" alignItems="center" height="65px" gap={2}>
          <FormControl
            sx={{ margin: "20px 0px", minWidth: "200px" }}
            size="small"
            disabled={userStores.length <= 0}
          >
            <InputLabel>Filtre por estabelecimento</InputLabel>
            <Select
              label="Filtre por estabelecimento"
              value={selectedStoreId}
              onChange={(e) => {
                setSelectedStoreId(e.target.value);
              }}
            >
              {userStores.map((store, index) => (
                <MenuItem value={userStores[index]?.id} key={store.id}>
                  <ListItem disablePadding>
                    <ListItemText primary={store.name} />
                  </ListItem>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box
        display="flex"
        gap={3}
        sx={{
          "@media (max-width: 1000px)": {
            flexDirection: "column",
            gap: 5,
          },
        }}
        marginBottom="30px"
      >
        {renderAppointmentsCalendar}
        {renderAppointmentsList}
      </Box>
    </Box>
  );
  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <GenericModal
        onClose={handleCloseCreateAppointmentModal}
        open={isCreateAppointmentModalOpen}
        content={
          <CreateAppointmentModal
            userStores={userStores.filter((store) => store.id !== "-")}
            userId={auth.user.id}
            selectedDate={selectedDate}
            defaultStoreId={selectedStoreId}
            onClose={handleCloseCreateAppointmentModal}
          />
        }
      />
      <AppointmentDetailsModal
        open={isAppointmentDetailsModalOpen}
        appointment={selectedUserAppointment}
        onClose={handleCloseAppointmentDetailsModal}
        showClient
      />
      <CEODrawer content={renderAppointmentsSection} />
    </Box>
  );
};

export default CEOAppointments;
