import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../../../components/general/CustomCard";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { IEmployee } from "../../../../services/api/Employee/types";
import serviceApi from "../../../../services/api/Service";
import serviceEmployeeApi from "../../../../services/api/ServiceEmployee";
import serviceStoreApi from "../../../../services/api/ServiceStore";
import { IServiceStore } from "../../../../services/api/ServiceStore/types";
import {
  generalDarkGrey,
} from "../../../../styles/colors";
import { priceFormatter } from "../../../../utils/priceFormatter";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";

const CURRENT_DAY_INDEX = new Date().getDay();

export const EmployeePage = () => {
  const navigate = useNavigate();

  const selectedEmployee: IEmployee = JSON.parse(
    localStorage.getItem("selectedEmployee")
  );

  const [serviceStores, setServiceStores] = useState<IServiceStore[]>([]);
  const [serviceNames, setServiceNames] = useState<string[]>([]);

  const [employeeServicesLoading, setEmployeeServicesLoading] = useState(false);

  const fetchServiceStoresFromEmployee = async (): Promise<void> => {
    setEmployeeServicesLoading(true);
    try {
      const employeeServiceEmployeesResponse =
        await serviceEmployeeApi.getServiceEmployeesFromEmployeeId(
          selectedEmployee.id
        );

      const serviceStoresAuxiliarArray: IServiceStore[] = [];
      const serviceNamesAuxiliarArray: string[] = [];

      let iterableServiceName: string = "";
      for (
        let index = 0;
        index < employeeServiceEmployeesResponse.length;
        index++
      ) {
        serviceStoresAuxiliarArray.push(
          employeeServiceEmployeesResponse[index].ServiceStore
        );
        iterableServiceName = (
          await serviceApi.getService(
            employeeServiceEmployeesResponse[index].ServiceStore.serviceId
          )
        ).name;
        serviceNamesAuxiliarArray.push(iterableServiceName);
      }

      setServiceStores(serviceStoresAuxiliarArray);
      setServiceNames(serviceNamesAuxiliarArray);
      setEmployeeServicesLoading(false);
    } catch (error) {
      console.warn("Error fetching service-stores from employee: ", error);
    }
    setEmployeeServicesLoading(false);
  };

  useEffect(() => {
    fetchServiceStoresFromEmployee();
  }, []);

  const handleSelectServiceClick = async (
    storeId: string,
    serviceName: string
  ) => {
    try {
      const res =
        await serviceStoreApi.getServiceStoresFromServiceNameAndStoreId(
          serviceName,
          storeId
        );
      localStorage.setItem("selectedServiceStore", JSON.stringify(res[0]));
      navigate(`/service?employeeId=${selectedEmployee.id}`);
    } catch (error) {
      console.warn("Error getting service-store: ", error);
    }
  };

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar hideMidButtons />
      <BackPageButton
        containerStyles={styles.backButton}
        iconStyles={styles.backIcon}
      />
      <Box
        width="100vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        margin="40px 0px"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems="center"
          justifyContent="center"
        >
          <Avatar
            src={selectedEmployee.User.imageOriginal}
            sx={{ width: 150, height: 150 }}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography variant="h5" fontWeight="bold">
              {selectedEmployee.User.name}
            </Typography>
            <Typography color="text.secondary">Cargo</Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={2} margin="30px 0px">
          <Typography fontWeight="bold" variant="body1" alignItems="center">
            Contatos:
          </Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" gap={1}>
              <LocalPhoneIcon color="primary" />
              <Typography variant="body1">
                {selectedEmployee.User.phone || "-"}
              </Typography>
            </Box>
            <Box display="flex" gap={1}>
              <AlternateEmailIcon color="primary" />
              <Typography variant="body1">
                {selectedEmployee.User.email || "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography variant="h5" fontWeight="bold" margin="20px 0px">
          Serviços deste funcionário
        </Typography>
        <Box
          display="flex"
          sx={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            width: "90%",
            p: "10px",
            mb: "20px",
          }}
          gap={2}
        >
          {serviceStores.length > 0 ? (
            serviceStores.map((serviceStore: IServiceStore, index) => (
              <Box
                key={serviceStore.id}
                onClick={() => {
                  handleSelectServiceClick(
                    serviceStore.storeId,
                    serviceNames[index]
                  );
                }}
              >
                <CustomCard
                  photoURL={serviceStore.imagePreview[0]}
                  defaultPhoto={""}
                  header={`R$ ${priceFormatter(
                    serviceStore.priceDay[CURRENT_DAY_INDEX]
                  )}`}
                  primaryText={serviceNames[index]}
                  secondaryText={serviceStore.Store.name}
                />
              </Box>
            ))
          ) : employeeServicesLoading ? (
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress sx={{ margin: "50px 0px" }} />
            </Box>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <Typography color={generalDarkGrey}>
                Este funcionário não está associado a nenhum serviço.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  backButton: {
    margin: "5px",
  },

  backIcon: {
    width: "30px",
    height: "30px",
  },
};
