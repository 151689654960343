import doRequest from "../util/provider";
import IExcursion from "./IExcursion";

const EXCURSION_URL = "/excursion";

const excursionApi = {
  getExcursion: async (id: string): Promise<IExcursion> =>
    await doRequest.get(`${EXCURSION_URL}/${id}`),

  getAllExcursions: async (): Promise<IExcursion[]> =>
    await doRequest.get(EXCURSION_URL),

  createExcursion: async (newExcursion: IExcursion): Promise<IExcursion> =>
    await doRequest.post(EXCURSION_URL, newExcursion),

  updateExcursion: async (
    id: string,
    updatedExcursion: IExcursion
  ): Promise<IExcursion> =>
    await doRequest.patch(`${EXCURSION_URL}/${id}`, updatedExcursion),

  deleteExcursion: async (id: string): Promise<void> =>
    await doRequest.delete(`${EXCURSION_URL}/${id}`),
  getExcursionsFromAStore: async (storeId: string): Promise<IExcursion[]> =>
    await doRequest.get(`${EXCURSION_URL}?storeId=${storeId}`),
};

export default excursionApi;
