import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  uploadBytes,
  deleteObject,
  listAll,
  getDownloadURL,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCjw4Nazp3CebomQ7G11rG8jiEyPr7M5Aw",
  authDomain: "demandoria-auth.firebaseapp.com",
  projectId: "demandoria-auth",
  storageBucket: "demandoria-auth.appspot.com",
  messagingSenderId: "729888061740",
  appId: "1:729888061740:web:a9c2d49359657f4de65489",
};

export const app = initializeApp(FIREBASE_CONFIG);
export const storage = getStorage(app);

export const uploadImagesToFirebase = async (
  canvasElements: HTMLCanvasElement[],
  imageUrls: string[],
  pathReference: string,
  sufix: string,
  uuids?: string[]
): Promise<string[]> => {
  let iterableConvertedImageBlob = null;
  let iterableImageId = "";

  const uploadedImageIds = [];

  let iterableCanvasElement = null;
  for (let i = 0; i < canvasElements.length; i++) {
    iterableCanvasElement = canvasElements[i];

    iterableImageId = uuids!.length !== 0 ? uuids[i] : uuidv4();
    uploadedImageIds.push(iterableImageId);

    iterableConvertedImageBlob = await new Promise<Blob>((resolve) =>
      iterableCanvasElement.toBlob(resolve)
    );

    const storageRef = ref(
      storage,
      `${pathReference}/${iterableImageId}${sufix}`
    );

    const snapshot = await uploadBytes(storageRef, iterableConvertedImageBlob);
    const uploadedImageUrl = await getDownloadURL(snapshot.ref);

    imageUrls.push(uploadedImageUrl);
  }

  return uploadedImageIds;
};

export const cleanStoreFirebaseFolder = async (storeName: string) => {
  const imagesRef = ref(storage, `images/stores/${storeName}/store-images/`);

  try {
    const imageList = await listAll(imagesRef);

    const deletePromises = imageList.items.map(async (imageRef) => {
      await deleteObject(imageRef);
    });

    await Promise.all(deletePromises);

    console.log(`${storeName} store firebase images deleted with success.`);
  } catch (error) {
    console.log(
      `Error deleting${storeName} store-images from firebase:`,
      error
    );
  }
};

export const cleanStoreExcursionsFirebaseFolder = async (
  storeName: string,
  excursionTitle: string
) => {
  const imagesRef = ref(
    storage,
    `images/stores/${storeName}/excursions/${excursionTitle}`
  );

  try {
    const imageList = await listAll(imagesRef);

    const deletePromises = imageList.items.map(async (imageRef) => {
      await deleteObject(imageRef);
    });

    await Promise.all(deletePromises);

    console.log(`${storeName} store firebase images deleted with success.`);
  } catch (error) {
    console.log(
      `Error deleting${storeName} store-images from firebase:`,
      error
    );
  }
};

export const cleanServiceStoreFirebaseFolder = async (
  store: string,
  service: string
) => {
  const imagesRef = ref(
    storage,
    `images/stores/${store}/services/${service}`
  );

  try {
    const imageList = await listAll(imagesRef);

    const deletePromises = imageList.items.map(async (imageRef) => {
      await deleteObject(imageRef);
    });

    await Promise.all(deletePromises);

    console.log(`${store} store firebase images deleted with success.`);
  } catch (error) {
    console.log(
      `Error deleting ${store} store-images from firebase:`,
      error
    );
  }
};

export const deleteImageFromFirebase = async (
  imageUrl: string,
  setState?: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const storageRef = ref(storage, imageUrl);

  try {
    await deleteObject(storageRef);
    console.log(`${imageUrl} deleted with success.`);

    if (setState !== undefined)
      setState((prevUrls) => prevUrls.filter((url) => url !== imageUrl));
  } catch (error) {
    console.log(`Error deleting ${imageUrl} from Firebase:`, error);
  }
};
