import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import { useNavigate } from "react-router-dom";
import TuristIcon from "../../../assets/img/turist.png";
import TuristGuideIcon from "../../../assets/img/turistGuide.png";
import LoginIcon from "@mui/icons-material/Login";
import styles from "./styles";
import Footer from "../../../components/general/Footer";

const TurismLandingPage = () => {
  const navigate = useNavigate();

  const firstSection = (
    <section style={styles.firstSectionContainer}>
      <Box sx={styles.firstSectionHeader}>
        <Box sx={{ marginLeft: "15px" }}>
          <Typography variant="h5" fontWeight="bold">
            Demandoria Turismo
          </Typography>
        </Box>
        <Box sx={styles.alreadyRegisteredContainer}>
          <Typography sx={styles.alreadyRegisteredLabel}>
            Já possui conta?
          </Typography>
          <Button
            sx={styles.loginButton}
            disableElevation
            variant="outlined"
            onClick={() => navigate("/login")}
          >
            Realizar login
          </Button>
        </Box>
      </Box>
      <Box sx={styles.presentationContainer}>
        <Typography variant="h4" fontWeight="bold">
          Explore o mundo com o
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: "primary.main", marginLeft: "8px" }}
          >
            Demandoria Turismo
          </Typography>
        </Typography>
        <Typography variant="h6" fontWeight="regular">
          Transforme suas viagens dos sonhos em realidade com nossos pacotes de
          excursão
        </Typography>

        <Button
          sx={styles.registerButton}
          disableElevation
          variant="contained"
          startIcon={<LoginIcon />}
          onClick={() => navigate("/register")}
        >
          Cadastrar-se
        </Button>
      </Box>
    </section>
  );

  const secondSection = (
    <section style={styles.secondSectionContainer}>
      <Box sx={styles.secondSectionHeader}>
        <Typography variant="h5" fontWeight="bold">
          Seja você turista ou agência
        </Typography>
        <Typography>
          Diferentes tipos de conta para diferentes necessidades
        </Typography>
      </Box>
      <Box sx={styles.roleCardContainer}>
        <Box sx={styles.roleCard}>
          <img
            src={TuristIcon}
            alt="Turist icon"
            style={styles.roleCardImage}
          />
          <Typography variant="h6" fontWeight="bold">
            Cliente
          </Typography>
          <Typography>
            Participe de excursões inesquecíveis disponíveis em nosso extenso
            catálogo
          </Typography>
        </Box>
        <Box sx={styles.roleCard}>
          <img
            src={TuristGuideIcon}
            alt="Turist Guide icon"
            style={styles.roleCardImage}
          />
          <Typography variant="h6" fontWeight="bold">
            Comercial
          </Typography>
          <Typography>
            Cadastre seus estabelecimentos e suas excursões para seus clientes
          </Typography>
        </Box>
      </Box>
    </section>
  );

  return (
    <>
      {/* <Helmet>
        <title>
          Demandoria | Agende os melhores serviços sem sair de casa!
        </title>
        <meta
          name="description"
          content="Revolucione sua forma de agendar serviços com o Demandoria! Nós oferecemos um agendamento rápido e prático para uma variedade de serviços. Encontre e reserve os melhores profissionais, tudo isso sem sair de casa. Experimente hoje mesmo."
        />
        <meta
          name="keywords"
          content="ecommerce, services, schedule, agendamento de serviços, agendar online, demandoria, serviços, marketplace"
        />
      </Helmet> */}
      <Box width="100%" height="100%">
        {firstSection}
        {secondSection}
        <Footer />
      </Box>
    </>
  );
};

export default TurismLandingPage;
